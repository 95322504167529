import React, { useCallback } from 'react'
import cn from 'classnames'
import s from './Button.module.scss'
import { useRouter } from 'next/router'
import { ArrowRight } from '@/icons'
import { Loading } from '@/icons'
import useLocale from '@/hooks/useLocale'

type ButtonProps = {
  className?: string
  isShowH5?: boolean //h5是否展示
  link?: string
  text?: string
  isFade?: boolean
  theme?: string // dark  light
  type?: 'Arrow' | 'smArrow' | 'linear' | 'primary' | 'secondary' | 'green'
  arrow?: boolean
  onClick?: (e: any) => void
  onDblClick?: (e: any) => void
  needAos?: boolean
  isLoading?: boolean
  target?: '_blank' | '_self'
  size?: 'big' | 'middle' | 'small'
  disabled?: boolean
}
const Button: React.FC<ButtonProps> = ({
  text = '',
  isShowH5 = true,
  link = '',
  theme = '',
  className = '',
  type = 'Arrow',
  needAos = true,
  isLoading = false,
  target = '_self',
  arrow = true,
  size = 'big',
  disabled = false,
  onClick,
  onDblClick }) => {
  const router = useRouter()
  const { t } = useLocale()

  const handleNavigation = useCallback((url: string, target: string) => {
    if (url.startsWith('http') || target === '_blank') {
      const prefix = !url.startsWith('http') && router.locale !== 'en' ? `/${router.locale}` : ''
      window.open(prefix + url, '_blank')
    } else {
      router.push(url)
    }
  }, [router])




  const onClickButton = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (isLoading) return
      if (!link) {
        onClick?.(e)
        return
      }
      handleNavigation(link, target)
    },
    [handleNavigation, isLoading, link, onClick, target]
  )
  const onDblClickButton = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    if (isLoading) return
    if (!link) {
      onDblClick?.(e)
      return
    }
    handleNavigation(link, target)
  },
    [handleNavigation, isLoading, link, onDblClick, target]
  )

  return (
    <button
      className={cn(
        s.button,
        s[`${size}Button`],
        s[`button${type}`],
        { [s.loadingButton]: isLoading, [s.bottonHidden]: !isShowH5, [s.bottonDeep]: type == 'Arrow' && theme == 'dark', [s.shallow]: theme == 'light' },
        className
      )}
      onClick={onClickButton}
      onDoubleClick={onDblClickButton}
      disabled={disabled}
      data-aos={needAos && !isLoading ? 'fade-up' : ''}
    >
      {isLoading ? (
        <span className={s.loadingText}>
          <Loading className={s.loading}></Loading>
          {t('Submitting')}
        </span>
      ) : (
        <>
          {text} {arrow && <ArrowRight size="16" className={cn(s.arrow, { [s.arrowAr]: router.locale == 'ar' })} />}{' '}
        </>
      )}
    </button>
  )
}
export default Button
